import React from 'react';
import { FaFileImage } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

export function HabitationCard({ habitation, platform }) {
  return (
    <div className={'col-lg-4'}>
      <div className={'card mb-4'}>
        {habitation.platform !== 'EAA' && (
          <>
            {habitation.thumbnail ? (
              <div
                className='habitation-thumbnail'
                style={{
                  backgroundImage: `url(${habitation.thumbnail})`,
                }}
              ></div>
            ) : (
              <div className='habitation-thumbnail justify-content-center d-flex align-items-center'>
                <FaFileImage />
              </div>
            )}
          </>
        )}

        <div
          className={
            habitation.platform !== 'EAA'
              ? 'card-body'
              : 'card-body card-body-eaa'
          }
        >
          <Link
            className={'card-title'}
            to={`/programas/${encodeURIComponent(
              habitation.platform
            )}/concursos/${encodeURIComponent(
              habitation.competitionCode
            )}/alojamentos/${habitation.externalId}`}
          >
            {habitation.platform !== 'EAA' ? (
              <>
                {habitation.parishName}, {habitation.countyName}
              </>
            ) : (
              <>
                {habitation.code} - {habitation.countyName}
              </>
            )}
          </Link>
          <p className={'card-text'}>
            {habitation.platform !== 'EAA' ? (
              <>
                <FormattedMessage id='habitationCard.apartmentText' />
                {habitation.typology} · {habitation.buildingArea} m<sup>2</sup>
                <br />
                <FormattedMessage id='habitationForm.rentToApplyText' />
                {' ' + habitation.rentToApply + ' €'}
              </>
            ) : (
              <>
                {habitation.typology} - {habitation.buildingArea}m2
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  );
}
