import React from 'react';
import { FaArrowsAltV, FaCheck, FaFileImage, FaLink } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

export function HabitationCardList({
  habitation,
  handleHabitationClick,
  order,
  view,
  showArrow,
}) {
  return (
    <div>
      {!order && !view && (
        <div className={'card card-list'}>
          <div className='row'>
            <div className='col-sm-11'>
              <Link
                className='link-select'
                onClick={() => handleHabitationClick(habitation)}
              >
                <div className='row'>
                  <div className='col'>
                    {habitation.selected ? (
                      <div className='order-symbol'>
                        <FaCheck />
                      </div>
                    ) : (
                      <div className='order-symbol unselected'></div>
                    )}
                  </div>

                  {habitation.platform !== 'EAA' && (
                    <div className='col-sm-3'>
                      <div className='d-flex align-items-center'>
                        <div className='p-1'>
                          {habitation.habitation.thumbnail ? (
                            <div
                              className='habitation-thumbnail'
                              style={{
                                backgroundImage: `url(${habitation.habitation.thumbnail})`,
                              }}
                            ></div>
                          ) : (
                            <div className='habitation-thumbnail justify-content-center d-flex align-items-center'>
                              <FaFileImage />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className='col-sm-8'>
                    <div className={'card-body'}>
                      <p className={'card-text'}>
                        <strong>
                          {habitation.habitation.parishName},{' '}
                          {habitation.habitation.countyName}
                        </strong>
                        <br />
                        <FormattedMessage id='habitationCard.apartmentText' />
                        {habitation.habitation.typology} ·{' '}
                        {habitation.habitation.buildingArea}m<sup>2</sup>
                        <br />
                        <FormattedMessage id='habitationForm.rentToApplyText' />
                        {' ' + habitation.habitation.rentToApply + ' €'}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className='col-sm-1 align-self-center'>
              <Link
                to={`/programas/${encodeURIComponent(
                  habitation.habitation.platform
                )}/concursos/${encodeURIComponent(
                  habitation.habitation.competitionCode
                )}/alojamentos/${habitation.habitation.externalId}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                <FaLink />
              </Link>
            </div>
          </div>
        </div>
      )}
      {order && !view && (
        <div className={'card card-list'}>
          <div className='row'>
            {order && showArrow && (
              <div className='col-sm-1 align-self-center text-right reorder text-primary'>
                <FaArrowsAltV />
              </div>
            )}
            <div className='col-sm-11'>
              <div className='row'>
                <div className='col-sm-3'>
                  <div className='d-flex align-items-center'>
                    <div className='p-1'>
                      {habitation.habitation.thumbnail ? (
                        <div
                          className='habitation-thumbnail'
                          style={{
                            backgroundImage: `url(${habitation.habitation.thumbnail})`,
                          }}
                        ></div>
                      ) : (
                        <div className='habitation-thumbnail justify-content-center d-flex align-items-center'>
                          <FaFileImage />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='col-sm-8'>
                  <div className={'card-body'}>
                    <p className={'card-text'}>
                      <strong>
                        {habitation.habitation.parishName},{' '}
                        {habitation.habitation.countyName}
                      </strong>
                      <br />
                      <FormattedMessage id='habitationCard.apartmentText' />
                      {habitation.habitation.typology} ·{' '}
                      {habitation.habitation.buildingArea}m<sup>2</sup>
                      <br />
                      <FormattedMessage id='habitationForm.rentToApplyText' />
                      {' ' + habitation.habitation.rentToApply + ' €'}
                    </p>
                  </div>
                </div>
                <div className='col'>
                  <div className='order-symbol reorder'>{habitation.order}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {view && !order && (
        <div className={'card card-list'}>
          <div className='row'>
            <div className='col-sm-11'>
              <div className='row'>
                <div className='col-sm-3'>
                  <div className='d-flex align-items-center'>
                    <div className='p-1'>
                      {habitation.habitation.thumbnail ? (
                        <div
                          className='habitation-thumbnail'
                          style={{
                            backgroundImage: `url(${habitation.habitation.thumbnail})`,
                          }}
                        ></div>
                      ) : (
                        <div className='habitation-thumbnail justify-content-center d-flex align-items-center'>
                          <FaFileImage />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='col-sm-8'>
                  <div className={'card-body'}>
                    <p className={'card-text'}>
                      <strong>
                        {habitation.habitation.parishName},{' '}
                        {habitation.habitation.countyName}
                      </strong>
                      <br />
                      <FormattedMessage id='habitationCard.apartmentText' />
                      {habitation.habitation.typology} ·{' '}
                      {habitation.habitation.buildingArea}m<sup>2</sup>
                      <br />
                      <FormattedMessage id='habitationForm.rentToApplyText' />
                      {' ' + habitation.habitation.rentToApply + ' €'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-1'>
              {habitation.order !== 0 && (
                <div className='order-symbol reorder'>{habitation.order}</div>
              )}
              <Link
                className='mt-5 d-block'
                to={`/programas/${encodeURIComponent(
                  habitation.habitation.platform
                )}/concursos/${encodeURIComponent(
                  habitation.habitation.competitionCode
                )}/alojamentos/${habitation.habitation.externalId}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                <FaLink />
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
